html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: $sky-blue;

  @include avant-garde(300);
  color: $text;
}

body > img {
  position: absolute;
  left: 0;
  bottom: 0;
}
html {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body {
	width: 100%;
	min-height: 100%;
}

figure {
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr,
dl,
dd,
dt,
ul,
ul li,
ol,
ol li,
figcaption,
blockquote,
cite,
.display-title,
.all-caps,
.field,
fieldset,
legend,
label,
input,
textarea,
.select,
.control,
progress,
caption,
table,
caption,
.table-responsive {
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

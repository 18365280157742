@mixin for-phone-small {
  @media(max-width: 360px) { @content; }
}
@mixin for-phone {
  @media(max-width: 767px) { @content; }
}
@mixin for-tablet-portrait {
  @media(max-width: 1023px) { @content; }
}
@mixin for-tablet-landscape {
  @media(max-width: 1199px) { @content; }
}
@mixin for-desktop {
  @media(min-width: 1200px) { @content; }
}
@import "https://use.typekit.net/fzc2fvf.css";
html {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: #0000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100%;
  min-height: 100%;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child, hr:first-child, dl:first-child, dd:first-child, dt:first-child, ul:first-child, ul li:first-child, ol:first-child, ol li:first-child, figcaption:first-child, blockquote:first-child, cite:first-child, .display-title:first-child, .all-caps:first-child, .field:first-child, fieldset:first-child, legend:first-child, label:first-child, input:first-child, textarea:first-child, .select:first-child, .control:first-child, progress:first-child, caption:first-child, table:first-child, caption:first-child, .table-responsive:first-child {
  margin-top: 0;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, hr:last-child, dl:last-child, dd:last-child, dt:last-child, ul:last-child, ul li:last-child, ol:last-child, ol li:last-child, figcaption:last-child, blockquote:last-child, cite:last-child, .display-title:last-child, .all-caps:last-child, .field:last-child, fieldset:last-child, legend:last-child, label:last-child, input:last-child, textarea:last-child, .select:last-child, .control:last-child, progress:last-child, caption:last-child, table:last-child, caption:last-child, .table-responsive:last-child {
  margin-bottom: 0;
}

.avant-garde {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.avant-garde-bold {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.typeface-h1 {
  letter-spacing: -1.12px;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
}

.typeface-h2 {
  letter-spacing: -.72px;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
}

.typeface-body {
  margin-top: 0;
  margin-bottom: .5em;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
}

.mb-0 {
  margin-bottom: 0;
}

@media (width <= 767px) {
  .typeface-h1 {
    font-size: 32px;
  }

  .typeface-h2 {
    font-size: 24px;
  }

  .typeface-body {
    font-size: 18px;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  color: #01235b;
  background: #ace2f5;
  margin: 0;
  padding: 0;
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
}

body > img {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sky-box {
  background-color: #ace2f5;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sky-box:before {
  content: "";
  background: linear-gradient(#fff0 0%, #ffffff80 100%);
  height: 100vh;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.eyeball-layer {
  position: absolute;
  inset: 0;
}

.clouds-bg {
  pointer-events: none;
  background: url("clouds-bg.5542c130.png") top / cover no-repeat;
  width: 100vw;
  height: 100%;
  position: absolute;
  inset: 0;
}

.clouds-fg {
  pointer-events: none;
  background: url("clouds-fg.c0d4cb6c.png") bottom / cover no-repeat;
  width: 100vw;
  height: 100%;
  position: absolute;
  inset: 0;
}

.test {
  background-color: #ace2f5;
  width: 100vw;
  height: 100vh;
  position: relative;
}

header {
  z-index: 5;
  position: relative;
}

header:after {
  content: "";
  z-index: -5;
  background: linear-gradient(#ffffffe6 15.97%, #ffffff80 60%, #ffffff40 80%, #fff0 100%);
  height: 70vh;
  display: block;
  position: absolute;
  inset: 0 0 auto;
}

header .header-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 23vh;
  display: flex;
  position: relative;
}

header .logos {
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px;
  display: flex;
  position: fixed;
  top: 0;
}

header .logos > div {
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  display: flex;
}

header .logos:after {
  content: "";
  z-index: -5;
  background: linear-gradient(#ffffffe6 15.97% 0%, #fff0 100%);
  width: 100%;
  height: 160px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

header .logo-keepsight {
  width: 250px;
  height: 80px;
}

header .logo-da {
  width: 160px;
  height: 55px;
}

header .logo-specsavers {
  width: 120px;
  height: 46px;
}

header .logo-da, header .logo-specsavers {
  opacity: 1;
  transition: all .5s;
  position: relative;
  transform: translateY(0);
}

header .join-button {
  opacity: 0;
  transition: all .5s;
  position: absolute;
  top: 32px;
  right: 32px;
  transform: translateY(50px);
}

header .typeface-h1 {
  text-align: center;
}

header .typeface-body {
  text-align: center;
  max-width: 675px;
}

header .scroll-for-more {
  color: #00a3de;
  margin-top: 100px;
  padding-top: 64px;
  font-size: 18px;
  font-style: normal;
  position: absolute;
  bottom: 100px;
}

header .scroll-for-more:before {
  content: "";
  border: 2px solid #00a3de;
  border-radius: 16px;
  width: 32px;
  height: 52px;
  position: absolute;
  top: 0;
  left: calc(50% - 16px);
}

header .scroll-for-more:after {
  content: "";
  background: #00a3de;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  animation: 2s ease-in-out infinite dot-bounce;
  position: absolute;
  top: 8px;
  left: calc(50% - 8px);
}

@media (width <= 767px) {
  header .logos {
    height: 145px;
    padding: 24px;
    transition: all .5s;
  }

  header .logos > div {
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }

  header .logos:after {
    height: 160px;
  }

  header .logo-keepsight {
    width: 180px;
    height: 58px;
  }

  header .logo-da {
    width: 100px;
    height: 34px;
  }

  header .logo-specsavers {
    width: 80px;
    height: 31px;
  }

  header .join-button {
    top: 24px;
    right: 24px;
  }
}

@media (width <= 360px) {
  header .logos {
    padding: 16px;
  }

  header .logos:after {
    height: 120px;
  }

  header .logo-keepsight {
    width: 144px;
    height: 46.4px;
  }

  header .logo-da {
    width: 80px;
    height: 27.2px;
  }

  header .logo-specsavers {
    width: 64px;
    height: 24.8px;
  }

  header .join-button {
    right: 16px;
  }

  header .join-button a {
    font-size: 14px;
  }
}

@media (width <= 767px) {
  .scrolled header .logos {
    height: 82px;
  }

  .scrolled header .logos > div {
    gap: 8px;
  }

  .scrolled header .logos .logo-da, .scrolled header .logos .logo-specsavers {
    transform: scale(.75);
  }
}

.no-js .logos .join-button {
  display: none;
}

@keyframes dot-bounce {
  0%, 100% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(20px);
  }
}

footer {
  text-align: center;
  background: #fff;
  padding: 175px 0 215px;
}

footer .corridor {
  max-width: 582px;
}

footer .social-links {
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.screen-full {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.screen-full.short {
  height: 75vh;
}

.screen-full.centered {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.screen-full.short.centered {
  justify-content: flex-end;
  padding-bottom: 25vh;
}

.corridor {
  text-align: center;
  max-width: 818px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.button {
  color: #fff;
  cursor: pointer;
  pointer-events: all;
  white-space: nowrap;
  background-color: #00a3de;
  border-radius: 32px;
  padding: 7px 20px 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  display: inline-block;
}

@media (width <= 767px) {
  .button {
    padding: 6px 16px 7px;
    font-size: 16px;
  }
}

.button-white {
  color: #00a3de;
  background-color: #fff;
}

.button-outline {
  color: #01235b;
  background-color: #fff;
  border: 2px solid #00a3de;
  padding: 5px 18px 6px;
}

.button-icon {
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  padding: 0;
  display: flex;
}

.eyeball {
  position: absolute;
}

.eyeball .ball {
  z-index: 1;
  background: radial-gradient(circle at 60% 40%, #fff 2%, #eef3f0 32%, #dce7e8 52%, #ccdde0 68%, #fbf9f6 100%) no-repeat;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.eyeball .iris {
  transform-origin: center;
  pointer-events: none;
  background: radial-gradient(circle at 60% 40%, #3f5d8d 0%, #1d3967 21.83%, #06204b 100%);
  border-radius: 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eyeball .pupil {
  transform-origin: center;
  background-color: #000;
  border-radius: 50%;
  width: 40%;
  height: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eyeball .pupil:after {
  content: "";
  background-color: #fff9;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.eyeball:after {
  content: "";
  pointer-events: none;
  background: url("knot.db848a98.png") top / contain no-repeat;
  width: 86px;
  height: 34px;
  display: block;
  position: absolute;
  top: 145px;
  left: 50%;
  transform: translateX(-50%);
}

.eyeball .string-canvas {
  z-index: 5;
  pointer-events: none;
  width: 140px;
  height: 640px;
  position: absolute;
  top: 168px;
  left: calc(50% - 84px);
}

.eyeball .highlight {
  transform-origin: center;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eyeball .highlight:before {
  content: "Tap to learn more";
  color: #01235b;
  text-align: center;
  background: #ffffffe6;
  border-radius: 50%;
  width: 116px;
  height: 116px;
  padding: 40px 16px;
  font-size: 14px;
  font-weight: 700;
  display: block;
  position: absolute;
  top: 15%;
  left: 15%;
  transform: translate(-50%, -50%);
}

.eyeball .highlight:after {
  content: "";
  transform-origin: center;
  border: 2px solid #ffffffbf;
  border-radius: 50%;
  width: 116px;
  height: 116px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 15%;
  left: 15%;
  transform: translate(-50%, -50%);
}

.eyeball .highlight.left:before, .eyeball .highlight.left:after {
  left: 85%;
}

.eyeball:focus .highlight, .eyeball.mouseover .highlight {
  opacity: 1;
  width: 360px;
  height: 360px;
}

.eyeball:focus .highlight:after, .eyeball.mouseover .highlight:after {
  width: 142px;
  height: 142px;
}

@media (width <= 767px) {
  .eyeball {
    transform: scale(.7);
  }
}

.eyeball-medium {
  filter: blur(2px);
}

.eyeball-medium .ball {
  width: 200px;
  height: 200px;
}

.eyeball-medium:after {
  width: 56.76px;
  height: 22.44px;
  top: 98px;
}

.eyeball-medium .string-canvas {
  width: 92.4px;
  height: 422.4px;
  top: 108px;
  left: calc(50% - 55.44px);
}

@media (width <= 767px) {
  .eyeball-medium {
    transform: scale(.66);
  }
}

.eyeball-small {
  filter: blur(3px);
}

.eyeball-small .ball {
  width: 150px;
  height: 150px;
}

.eyeball-small:after {
  width: 43px;
  height: 17px;
  top: 75px;
}

.eyeball-small .string-canvas {
  width: 70px;
  height: 320px;
  top: 80px;
  left: calc(50% - 42px);
}

@media (width <= 767px) {
  .eyeball-small {
    transform: scale(.66);
  }
}

.eyeball-xsmall {
  filter: blur(4px);
}

.eyeball-xsmall .ball {
  width: 100px;
  height: 100px;
}

.eyeball-xsmall:after {
  width: 28.38px;
  height: 11.22px;
  top: 49px;
}

.eyeball-xsmall .string-canvas {
  width: 46.2px;
  height: 211.2px;
  top: 51px;
  left: calc(50% - 27.72px);
}

@media (width <= 767px) {
  .eyeball-xsmall {
    transform: scale(.66);
  }
}

.video-overlay {
  z-index: 10;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  opacity: 0;
  pointer-events: none;
  background: #ace2f5bf linear-gradient(#ffffff80 16%, #fff0 90%) no-repeat;
  transition: all .5s .5s;
  position: fixed;
  inset: 0;
}

.video-overlay .video-player {
  cursor: pointer;
  background-color: #00a3de;
  border-radius: 10px;
  width: 396px;
  height: 704px;
  position: absolute;
  top: calc(50% - 352px);
  left: calc(50% - 198px);
  overflow: hidden;
}

@media (width <= 1199px) {
  .video-overlay .video-player {
    width: 300px;
    height: 526px;
    top: calc(50% - 296px);
    left: calc(50% - 150px);
  }
}

.video-overlay .video-player .vimeo, .video-overlay .video-player .play-control {
  display: block;
  position: absolute;
  inset: 0;
}

.video-overlay .video-player .play-control {
  background: none;
}

.video-overlay .video-player.paused .play-control {
  background: url("play.61d23821.svg") 50% 62% / 56px 56px no-repeat;
}

.video-overlay .video-content {
  background: #fff;
  border-radius: 10px;
  width: 268px;
  min-height: 244px;
  padding: 36px 24px;
  font-size: 16px;
  position: absolute;
  bottom: calc(50% - 352px);
  left: calc(50% + 258px);
}

.video-overlay .video-content h2 {
  letter-spacing: .48px;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.video-overlay .video-content p {
  margin-top: 0;
}

.video-overlay .video-content:before {
  content: "";
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-right: 14px solid #fff;
  width: 14px;
  height: 24px;
  display: block;
  position: absolute;
  top: 32px;
  left: -13px;
}

@media (width <= 1199px) {
  .video-overlay .video-content {
    background-color: #00a3de;
    border-radius: 0 0 10px 10px;
    width: 300px;
    min-height: auto;
    padding: 16px;
    transition: background-color .3s;
    bottom: calc(50% - 270px);
    left: calc(50% - 150px);
  }

  .video-overlay .video-content .fullscreen-control {
    background: none;
  }

  .video-overlay .video-content .fullscreen-control, .video-overlay .video-content:before {
    z-index: 2;
    cursor: pointer;
    background-color: #00a3de;
    border: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 12px;
    transition: background-color .3s;
    display: block;
    position: absolute;
    top: -28px;
    left: auto;
    right: 64px;
  }

  .video-overlay .video-content:before {
    content: url("data:image/svg+xml; utf8, <svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 6C11.1562 6 10.5 6.70312 10.5 7.5C10.5 8.34375 11.1562 9 12 9C12.7969 9 13.5 8.34375 13.5 7.5C13.5 6.70312 12.7969 6 12 6ZM13.875 18C14.4844 18 15 17.5312 15 16.875C15 16.2656 14.4844 15.75 13.875 15.75H13.125V11.625C13.125 11.0156 12.6094 10.5 12 10.5H10.5C9.84375 10.5 9.375 11.0156 9.375 11.625C9.375 12.2812 9.84375 12.75 10.5 12.75H10.875V15.75H10.125C9.46875 15.75 9 16.2656 9 16.875C9 17.5312 9.46875 18 10.125 18H13.875Z\" fill=\"white\"/></svg>");
  }

  .video-overlay .video-content .fullscreen-control {
    background: #00a3de url("data:image/svg+xml; utf8, <svg width=\"22\" height=\"22\" viewBox=\"0 0 22 22\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.5 0.5C7.29688 0.5 8 1.20312 8 2C8 2.84375 7.29688 3.5 6.5 3.5H3.5V6.5C3.5 7.34375 2.79688 8 2 8C1.15625 8 0.5 7.34375 0.5 6.5V2C0.5 1.20312 1.15625 0.5 2 0.5H6.5ZM20 0.5C20.7969 0.5 21.5 1.20312 21.5 2V6.5C21.5 7.34375 20.7969 8 20 8C19.1562 8 18.5 7.34375 18.5 6.5V3.5H15.5C14.6562 3.5 14 2.84375 14 2C14 1.20312 14.6562 0.5 15.5 0.5H20ZM6.5 18.5C7.29688 18.5 8 19.2031 8 20C8 20.8438 7.29688 21.5 6.5 21.5H2C1.15625 21.5 0.5 20.8438 0.5 20V15.5C0.5 14.7031 1.15625 14 2 14C2.79688 14 3.5 14.7031 3.5 15.5V18.5H6.5ZM20 14C20.7969 14 21.5 14.7031 21.5 15.5V20C21.5 20.8438 20.7969 21.5 20 21.5H15.5C14.6562 21.5 14 20.8438 14 20C14 19.2031 14.6562 18.5 15.5 18.5H18.5V15.5C18.5 14.7031 19.1562 14 20 14Z\" fill=\"white\"/></svg>") center no-repeat;
    right: 8px;
  }

  .video-overlay .video-content h2 {
    color: #fff;
    margin: 0;
    transition: color .3s;
  }

  .video-overlay .video-content p {
    margin-top: 8px;
    display: none;
  }

  .video-overlay .video-content.open {
    background-color: #fff;
  }

  .video-overlay .video-content.open h2 {
    color: #01235b;
  }

  .video-overlay .video-content.open:before {
    content: url("data:image/svg+xml; utf8, <svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 6C11.1562 6 10.5 6.70312 10.5 7.5C10.5 8.34375 11.1562 9 12 9C12.7969 9 13.5 8.34375 13.5 7.5C13.5 6.70312 12.7969 6 12 6ZM13.875 18C14.4844 18 15 17.5312 15 16.875C15 16.2656 14.4844 15.75 13.875 15.75H13.125V11.625C13.125 11.0156 12.6094 10.5 12 10.5H10.5C9.84375 10.5 9.375 11.0156 9.375 11.625C9.375 12.2812 9.84375 12.75 10.5 12.75H10.875V15.75H10.125C9.46875 15.75 9 16.2656 9 16.875C9 17.5312 9.46875 18 10.125 18H13.875Z\" fill=\"%2300a3de\"/></svg>");
    background-color: #fff;
  }

  .video-overlay .video-content.open .fullscreen-control {
    background: #fff url("data:image/svg+xml; utf8, <svg width=\"22\" height=\"22\" viewBox=\"0 0 22 22\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6.5 0.5C7.29688 0.5 8 1.20312 8 2C8 2.84375 7.29688 3.5 6.5 3.5H3.5V6.5C3.5 7.34375 2.79688 8 2 8C1.15625 8 0.5 7.34375 0.5 6.5V2C0.5 1.20312 1.15625 0.5 2 0.5H6.5ZM20 0.5C20.7969 0.5 21.5 1.20312 21.5 2V6.5C21.5 7.34375 20.7969 8 20 8C19.1562 8 18.5 7.34375 18.5 6.5V3.5H15.5C14.6562 3.5 14 2.84375 14 2C14 1.20312 14.6562 0.5 15.5 0.5H20ZM6.5 18.5C7.29688 18.5 8 19.2031 8 20C8 20.8438 7.29688 21.5 6.5 21.5H2C1.15625 21.5 0.5 20.8438 0.5 20V15.5C0.5 14.7031 1.15625 14 2 14C2.79688 14 3.5 14.7031 3.5 15.5V18.5H6.5ZM20 14C20.7969 14 21.5 14.7031 21.5 15.5V20C21.5 20.8438 20.7969 21.5 20 21.5H15.5C14.6562 21.5 14 20.8438 14 20C14 19.2031 14.6562 18.5 15.5 18.5H18.5V15.5C18.5 14.7031 19.1562 14 20 14Z\" fill=\"%2300a3de\"/></svg>") center no-repeat;
  }
}

.video-overlay .close-overlay {
  color: #01235b;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 160px;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  position: absolute;
  bottom: 35px;
  left: calc(50% - 80px);
}

.video-overlay .close-overlay:after {
  content: "";
  background: #00a3de url("cross.8978fb74.svg") center / 16px 16px no-repeat;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-left: 12px;
  display: inline-block;
}

@media (width <= 1199px) {
  .video-overlay .close-overlay {
    bottom: 16px;
  }

  .video-overlay .close-overlay:after {
    width: 32px;
    height: 32px;
  }
}

.video-overlay.open {
  opacity: 1;
  pointer-events: all;
  transition: all .5s;
}

html.video-overlay-open {
  overflow: hidden;
}

.sticky-footer {
  color: #fff;
  background-color: #00a3de;
  width: 100%;
  padding: 24px 0;
  font-weight: 700;
  position: fixed;
  bottom: 0;
  left: 0;
}

.sticky-footer .corridor {
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

@media (width <= 767px) {
  .sticky-footer .corridor {
    gap: 12px;
  }
}

.sticky-footer-text {
  text-align: left;
}

@media (width <= 767px) {
  .sticky-footer-text {
    font-size: 16px;
  }
}

/*# sourceMappingURL=index.f77ddd1c.css.map */

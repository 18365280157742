.typeface-h1 {
  @include avant-garde(700);
  font-size: 56px;
  line-height: 1.3;
  letter-spacing: -1.12px;
}

.typeface-h2 {
  @include avant-garde(700);
  font-size: 36px;
  line-height: 1.3;
  letter-spacing: -0.72px;
}

.typeface-body {
  @include avant-garde(300);
  font-size: 24px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.mb-0 {
  margin-bottom: 0;
}

@include for-phone {
  .typeface-h1 {
    font-size: 32px;
  }
  
  .typeface-h2 {
    font-size: 24px;
  }
  
  .typeface-body {
    font-size: 18px;
  }
}
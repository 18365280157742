@import url("https://use.typekit.net/fzc2fvf.css");

// Avant Garde Gothic Pro 300 & 700
@mixin avant-garde($weight: 300) {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
  font-weight: $weight;
  font-style: normal;
}
.avant-garde {
  @include avant-garde(300);
}
.avant-garde-bold {
  @include avant-garde(700);
}
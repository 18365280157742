footer {
  background: $white;
  text-align: center;
  padding: 175px 0 215px 0;

  .corridor {
    max-width: 550px + 32;
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}
$white: #fff;
$black: #000;

$off-white: #fec;
$sky-blue: #ace2f5;
$blue: #00a3de;
$navy: #01235b;

$text: $navy;

// Gradients
//$gradient-eye-ball: radial-gradient(circle at 60% 40%, #FFF 2%, #EFE8E3 33%, #E1DDCC 62%, #A9BCBA 100%);
$gradient-eye-ball: radial-gradient(circle at 60% 40%, #FFF 2%, #EEF3F0 32%, #DCE7E8 52%, #CCDDE0 68%, #FBF9F6 100%);
//$gradient-eye-iris: radial-gradient(circle at 60% 40%, #64bdfc 10%, #2d51b9 70%);
$gradient-eye-iris: radial-gradient(circle at 60% 40%, #3F5D8D 0%, #1D3967 21.83%, #06204B 100%);
.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $blue;
  color: $white;
  padding: 24px 0;
  font-weight: 700;

  .corridor {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    @include for-phone {
      gap: 12px;
    }
  }

}

.sticky-footer-text {
  text-align: left;
  @include for-phone {
    font-size: 16px;
  }
}
.video-overlay {
  position: fixed;
  inset: 0;
  z-index: 10;

  background: rgba($sky-blue, 0.75) linear-gradient(180deg, rgba($white, 0.5) 16%, rgba($white, 0) 90%) no-repeat;
  backdrop-filter: blur(2px);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s 0.5s;
  
  .video-player {
    position: absolute;
    left: calc(50% - 198px);
    top: calc(50% - 352px);
    width: 396px;
    height: 704px;
    border-radius: 10px;
    background-color: $blue;
    overflow: hidden;
    cursor: pointer;

    @include for-tablet-landscape {
      width: 300px;
      height: 526px;
      left: calc(50% - 150px);
      top: calc(50% - 296px);
    }

    .vimeo,
    .play-control {
      display: block;
      position: absolute;
      inset: 0;
    }

    .play-control {
      background: none;
    }

    &.paused .play-control {
      background: url('../images/play.svg') center 62% no-repeat;
      background-size: 56px 56px;
    }
  }

  .video-content {
    position: absolute;
    left: calc(50% + 58px + 200px);
    bottom: calc(50% - 352px); 
    width: 268px;
    min-height: 244px;
    border-radius: 10px;
    background: $white;
    padding: 36px 24px;
    font-size: 16px;

    h2 {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.48px;
      text-transform: uppercase;
      margin: 0 0 10px 0;
    }
    p {
      margin-top: 0;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 14px;
      height: 24px;
      left: -13px;
      top: 32px;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 14px solid white;
    }

    @include for-tablet-landscape {
      width: 300px;
      left: calc(50% - 150px);
      bottom: calc(50% - 270px);
      padding: 16px;
      min-height: auto;
      border-radius: 0 0 10px 10px;

      background-color: $blue;
      transition: background-color 0.3s;
      

      .fullscreen-control {
        background: none;
      }

      .fullscreen-control,
      &::before {
        display: block;
        position: absolute;
        top: -28px;
        left: auto;
        right: 64px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        padding: 12px;
        background-color: $blue;
        z-index: 2;
        transition: background-color 0.3s;
        cursor: pointer;
      }
      &::before {
        content: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 6C11.1562 6 10.5 6.70312 10.5 7.5C10.5 8.34375 11.1562 9 12 9C12.7969 9 13.5 8.34375 13.5 7.5C13.5 6.70312 12.7969 6 12 6ZM13.875 18C14.4844 18 15 17.5312 15 16.875C15 16.2656 14.4844 15.75 13.875 15.75H13.125V11.625C13.125 11.0156 12.6094 10.5 12 10.5H10.5C9.84375 10.5 9.375 11.0156 9.375 11.625C9.375 12.2812 9.84375 12.75 10.5 12.75H10.875V15.75H10.125C9.46875 15.75 9 16.2656 9 16.875C9 17.5312 9.46875 18 10.125 18H13.875Z" fill="white"/></svg>');
      }

      .fullscreen-control {
        right: 8px;
        background: $blue url('data:image/svg+xml; utf8, <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 0.5C7.29688 0.5 8 1.20312 8 2C8 2.84375 7.29688 3.5 6.5 3.5H3.5V6.5C3.5 7.34375 2.79688 8 2 8C1.15625 8 0.5 7.34375 0.5 6.5V2C0.5 1.20312 1.15625 0.5 2 0.5H6.5ZM20 0.5C20.7969 0.5 21.5 1.20312 21.5 2V6.5C21.5 7.34375 20.7969 8 20 8C19.1562 8 18.5 7.34375 18.5 6.5V3.5H15.5C14.6562 3.5 14 2.84375 14 2C14 1.20312 14.6562 0.5 15.5 0.5H20ZM6.5 18.5C7.29688 18.5 8 19.2031 8 20C8 20.8438 7.29688 21.5 6.5 21.5H2C1.15625 21.5 0.5 20.8438 0.5 20V15.5C0.5 14.7031 1.15625 14 2 14C2.79688 14 3.5 14.7031 3.5 15.5V18.5H6.5ZM20 14C20.7969 14 21.5 14.7031 21.5 15.5V20C21.5 20.8438 20.7969 21.5 20 21.5H15.5C14.6562 21.5 14 20.8438 14 20C14 19.2031 14.6562 18.5 15.5 18.5H18.5V15.5C18.5 14.7031 19.1562 14 20 14Z" fill="white"/></svg>') no-repeat center center;
      }

      h2 {
        margin: 0;
        color: $white;
        transition: color 0.3s;
      }
      p {
        margin-top: 8px;
        display: none;
      }
      /*
      &::before {
        display: none;
      }
      */

      &.open {
        background-color: $white;
        h2 {
          color: $navy;
        }
        &::before {
          content: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 6C11.1562 6 10.5 6.70312 10.5 7.5C10.5 8.34375 11.1562 9 12 9C12.7969 9 13.5 8.34375 13.5 7.5C13.5 6.70312 12.7969 6 12 6ZM13.875 18C14.4844 18 15 17.5312 15 16.875C15 16.2656 14.4844 15.75 13.875 15.75H13.125V11.625C13.125 11.0156 12.6094 10.5 12 10.5H10.5C9.84375 10.5 9.375 11.0156 9.375 11.625C9.375 12.2812 9.84375 12.75 10.5 12.75H10.875V15.75H10.125C9.46875 15.75 9 16.2656 9 16.875C9 17.5312 9.46875 18 10.125 18H13.875Z" fill="%2300a3de"/></svg>');
          background-color: $white;
        }
        .fullscreen-control {
          background: $white url('data:image/svg+xml; utf8, <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 0.5C7.29688 0.5 8 1.20312 8 2C8 2.84375 7.29688 3.5 6.5 3.5H3.5V6.5C3.5 7.34375 2.79688 8 2 8C1.15625 8 0.5 7.34375 0.5 6.5V2C0.5 1.20312 1.15625 0.5 2 0.5H6.5ZM20 0.5C20.7969 0.5 21.5 1.20312 21.5 2V6.5C21.5 7.34375 20.7969 8 20 8C19.1562 8 18.5 7.34375 18.5 6.5V3.5H15.5C14.6562 3.5 14 2.84375 14 2C14 1.20312 14.6562 0.5 15.5 0.5H20ZM6.5 18.5C7.29688 18.5 8 19.2031 8 20C8 20.8438 7.29688 21.5 6.5 21.5H2C1.15625 21.5 0.5 20.8438 0.5 20V15.5C0.5 14.7031 1.15625 14 2 14C2.79688 14 3.5 14.7031 3.5 15.5V18.5H6.5ZM20 14C20.7969 14 21.5 14.7031 21.5 15.5V20C21.5 20.8438 20.7969 21.5 20 21.5H15.5C14.6562 21.5 14 20.8438 14 20C14 19.2031 14.6562 18.5 15.5 18.5H18.5V15.5C18.5 14.7031 19.1562 14 20 14Z" fill="%2300a3de"/></svg>') no-repeat center center;
        }
      }
    }
  }

  .close-overlay {
    position: absolute;
    left: calc(50% - 80px);
    bottom: 35px;
    width: 160px;
    color: $navy;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    background: none;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $blue url('../images/cross.svg') center center no-repeat;
      background-size: 16px 16px;
      margin-left: 12px;
    }

    @include for-tablet-landscape {
      bottom: 16px;
      &::after {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.open {
    opacity: 1;
    pointer-events: all;
    transition: 0.5s;
  }

}

html.video-overlay-open {
  overflow: hidden;
}
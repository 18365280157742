.button {
  display: inline-block;
  color: $white;
  background-color: $blue;
  padding: 7px 20px 8px 20px;
  border-radius: 32px;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  text-decoration: none;
  cursor: pointer;
  pointer-events: all;
  white-space: nowrap;

  @include for-phone {
    font-size: 16px;
    padding: 6px 16px 7px 16px;
  }
}

.button-white {
  color: $blue;
  background-color: $white;
}

.button-outline {
  background-color: $white;
  border: 2px solid $blue;
  padding: 5px 18px 6px 18px;
  color: $text;
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 42px;
  height: 42px;
}
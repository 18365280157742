.sky-box {
  position: relative;
  width: 100%;
  background-color: $sky-blue;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.5) 100%);
  }
}

.eyeball-layer {
  position: absolute;
  inset: 0;
}

.clouds-bg {
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100%;
  background: url('../images/clouds-bg.png') center top no-repeat;
  background-size: cover;
  pointer-events: none;
}

.clouds-fg {
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100%;
  background: url('../images/clouds-fg.png') center bottom no-repeat;
  background-size: cover;
  pointer-events: none;
}

.test {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: $sky-blue;
}
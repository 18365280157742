.eyeball {
  position: absolute;
  
  .ball {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: $white;
    background: $gradient-eye-ball;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 1;
  }

  .iris {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: #1D3967;
    background: $gradient-eye-iris;
    transform-origin: center;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .pupil {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    background-color: $black;
    transform-origin: center;
    transform: translate(-50%, -50%);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: rgba($white, 0.6);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 145px;
    left: 50%;
    width: 86px;
    height: 34px;
    background: url('../images/knot.png') center top no-repeat;
    background-size: contain;
    transform: translateX(-50%);
    pointer-events: none;
  }

  .string-canvas {
    position: absolute;
    top: 168px;
    left: calc(50% - 84px);
    width: 140px;
    height: 640px;
    z-index: 5;
    pointer-events: none;
  }

  .highlight {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 2px solid $white;
    transform-origin: center;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
    z-index: 2;

    &::before {
      content: "Tap to learn more";
      position: absolute;
      display: block;
      top: 15%;
      left: 15%;
      width: 116px;
      height: 116px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      color: $navy;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      background: rgba($white, 0.9);
      padding: 40px 16px;
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 15%;
      left: 15%;
      width: 116px;
      height: 116px;
      border-radius: 50%;
      border: 2px solid rgba($white, 0.75);
      transform-origin: center;
      transform: translate(-50%, -50%);
      transition: 0.5s;
    }

    &.left {
      &::before,
      &::after {
        left: 85%;
      }
    }
  }

  &:focus .highlight,
  &.mouseover .highlight {
    width: 360px;
    height: 360px;
    opacity: 1;
    &::after {
      width: 142px;
      height: 142px;
    }
  }

  @include for-phone {
    transform: scale(0.7);
  }
}

.eyeball-medium {
  filter:blur(2px);
  .ball {
    width: 200px;
    height: 200px;
  }
  &::after {
    top: 98px;
    width: 86px * 0.66;
    height: 34px * 0.66;
  }
  .string-canvas {
    top: 108px;
    left: calc(50% - (84px * 0.66));
    width: 140px * 0.66;
    height: 640px * 0.66;
  }
  @include for-phone {
    transform: scale(0.66);
  }
}

.eyeball-small {
  filter:blur(3px);
  .ball {
    width: 150px;
    height: 150px;
  }
  &::after {
    top: 75px;
    width: 86px * 0.5;
    height: 34px * 0.5;
  }
  .string-canvas {
    top: 80px;
    left: calc(50% - (84px * 0.5));
    width: 140px * 0.5;
    height: 640px * 0.5;
  }
  @include for-phone {
    transform: scale(0.66);
  }
}

.eyeball-xsmall  {
  filter:blur(4px);
  .ball {
    width: 100px;
    height: 100px;
  }
  &::after {
    top: 49px;
    width: 86px * 0.33;
    height: 34px * 0.33;
  }
  .string-canvas {
    top: 51px;
    left: calc(50% - (84px * 0.33));
    width: 140px * 0.33;
    height: 640px * 0.33;
  }
  @include for-phone {
    transform: scale(0.66);
  }
}

header {
  position: relative;
  z-index: 5;

  &::after {
    content:"";
    display: block;
    position: absolute;
    inset: 0;
    bottom: auto;
    height: 70vh;
    background: linear-gradient(180deg, rgba($white, 0.9) 15.97%, rgba($white, 0.5) 60%, rgba($white, 0.25) 80%, rgba($white, 0) 100%);
    z-index: -5;
  }

  .header-content {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 23vh;
  }

  .logos {
    //position: absolute;
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 32px;
    }

    &::after {
      content:"";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 160px;
      background: linear-gradient(180deg, rgba($white, 0.9) 15.97% 0%, rgba($white, 0) 100%);
      z-index: -5;
    }

  }

  .logo-keepsight {
    width: 250px;
    height: 80px;
  }
  .logo-da {
    width: 160px;
    height: 55px;
  }
  .logo-specsavers {
    width: 120px;
    height: 46px;
  }
  .logo-da,
  .logo-specsavers {
    opacity: 1;
    position: relative;
    transform: translateY(0);
    transition: 0.5s;
  }

  .join-button {
    position: absolute;
    top: 32px;
    right: 32px;
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s;
  }

  .typeface-h1 {
    text-align: center;
  }

  .typeface-body {
    max-width: 675px;
    text-align: center;
  }

  .scroll-for-more {
    position: absolute;
    bottom: 100px;
    color: $blue;
    font-size: 18px;
    font-style: normal;
    padding-top: 64px;
    margin-top: 100px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 16px);
      width: 32px;
      height: 52px;
      border: 2px solid $blue;
      border-radius: 16px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      background: $blue;
      border-radius: 8px;
      animation: dot-bounce 2s infinite ease-in-out;
    }

  }

  @include for-phone {
    .logos {
      padding: 24px;
      transition: 0.5s;
      height: 145px;
      > div {
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
      }
      &::after {
        height: 160px;
      }
    }
    .logo-keepsight {
      width: 180px;
      height: 58px;
    }
    .logo-da {
      width: 100px;
      height: 34px;
    }
    .logo-specsavers {
      width: 80px;
      height: 31px;
    }
    .join-button {
      right: 24px;
      top: 24px;
    }
  }

  @include for-phone-small {
    .logos {
      padding: 16px;
      &::after {
        height: 120px;
      }
    }
    .logo-keepsight {
      width: 180px * 0.8;
      height: 58px * 0.8;
    }
    .logo-da {
      width: 100px * 0.8;
      height: 34px * 0.8;
    }
    .logo-specsavers {
      width: 80px * 0.8;
      height: 31px * 0.8;
    }
    .join-button {
      right: 16px;
      //top: 16px;
      a {
        font-size: 14px;
      }
    }
  }

}

.scrolled header {
  .logos {
    @include for-phone {
      height: 58px + 24;
      > div {
        gap: 8px;
      }
      .logo-da,
      .logo-specsavers {
        transform: scale(0.75);
      }
    }
    
    /*
    .logo-da,
    .logo-specsavers {
      opacity: 0;
      transform: translateY(-50px);
    }
    .join-button {
      opacity: 1;
      transform: translateY(0);
    }
    */
  }
}

.no-js {
  .logos .join-button {
    display: none;
  }
}

@keyframes dot-bounce {
  0%, 100%  { transform: translateY(0); }
  33%       { transform: translateY(52px - 16 - 16); }
}
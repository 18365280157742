.screen-full {
  position: relative;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &.short {
    height: 75vh;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.short.centered {
    justify-content: flex-end;
    padding-bottom: 25vh;
  }
}
